<template>
	<div>
		<table id="prestations" class="mobile-table table-sticky">
			<thead>
				<tr>
					<th id="title-name" rowspan="2">
						<div> {{$t("common.name")}} </div>
					</th>
					<th rowspan="1" v-if="$store.getters.salonPrestationsHasLength()">
						<div> {{$t("common.hair")}} </div>
					</th>
					<!-- <th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2"> -->
					<th>
						<div> {{$t("common.price")}} </div>
					</th>
					<!-- <th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2"> -->
					<th>
						<div> {{$t("common.durate")}} </div>
					</th>
					<th v-if="$store.getters.salonPrestationsHasDependencies()" rowspan="2" class="dependency-col">
						<div> {{$t("common.dependence")}} </div>
					</th>
					<th v-if="$store.state.domain == 'esthetics'" rowspan="3">
						<div> <span id="title-cat">{{ $t("common.step") }}<br/><i>{{ $t("common.type") }}</i></span> </div>
					</th>
					<th rowspan="2">
						<div> {{ $t("common.action") }} </div>
					</th>
				</tr>
				<!-- <tr>
					<th colspan="1">
						{{ $t("common.wom") }}
					</th>
					<th colspan="1">
						{{ $t("common.men") }}
					</th>
					<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
						{{ $t("common.youth") }}
					</th>
					<th colspan="1">
						{{ $t("common.wom") }}
					</th>
					<th colspan="1">
						{{ $t("common.man") }}
					</th>
					<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
						{{ $t("common.youth") }}
					</th>
				</tr> -->
			</thead>
			<tbody>
				<template v-for="service in services">
					<tr
						:key="service.id + '-1'"
						:class="['row-border-top', activeServices.indexOf(service.id) >= 0 && 'active']"
						@click="toggleServiceActive(service)"
					>
						<td class="max-width-1 table-name-mobile" :rowspan="service.rowspan">
							{{ service.title }}
							<br/>
							<span v-if="service.uses_stock" class="text-muted text-white">{{ $t("common.usesstock") }}</span>
						</td>
						<td v-if="$store.getters.salonPrestationsHasLength()" class="hair desktop" :rowspan="1" :class="service.oneline ? 'mobile-none' : ''">
							{{ service.oneline ? '' : $t('data.hairlength.short') }}
						</td>
						<ServiceMatrixMobile class="mobile" :service="service"></ServiceMatrixMobile>
						<td v-if="service.price_female != null" class="td-small cell-f desktop" :rowspan="service.rowspan">
							{{ service.price_female }}
						</td>
						<!-- <td v-else class="td-small cell-f desktop" :rowspan="1">
							{{ service.female_short_price }}
						</td> -->
						<!-- <td v-if="service.price_male != null" class="td-small cell-h desktop" :rowspan="service.rowspan">
							{{ service.price_male }}
						</td> -->
						<!-- <td v-else class="td-small cell-h desktop" :rowspan="1">
							{{ service.male_short_price }}
						</td> -->
						<!-- <td class="td-small cell-enf desktop" :rowspan="service.rowspan" v-if="$store.getters.salonAcceptsChildren()">
							{{ service.child_price }}
						</td> -->
						<td v-if="service.duration_female != null" class="td-small cell-f desktop" :rowspan="service.rowspan">
							{{ service.duration_female }}
						</td>
						<!-- <td v-else class="td-small cell-f desktop" :rowspan="1">
							{{ service.female_short_time }}
						</td> -->
						<!-- <td v-if="service.duration_male != null" class="td-small cell-h desktop" :rowspan="service.rowspan">
							{{ service.duration_male }}
						</td> -->
						<!-- <td v-else class="td-small cell-h desktop" :rowspan="1">
							{{ service.male_short_time }}
						</td>
						<td class="td-small cell-enf desktop" :rowspan="service.rowspan" v-if="$store.getters.salonAcceptsChildren()">
							{{ service.child_time }}
						</td> -->
						<th v-if="$store.getters.salonPrestationsHasDependencies() && service.computedDependencies.length" class="mobile">{{ $t("common.dependencies") }}</th>
						<td v-if="$store.getters.salonPrestationsHasDependencies()" class="td-very-small dependency-col" :class="{ desktop: !service.computedDependencies.length }" :rowspan="service.rowspan">
							{{ service.computedDependencies.reduce( ( a, b ) => { return a + ( a != "" ? " + " : "" ) + b.title }, "" ) }}
						</td>
						<th v-if="$store.state.domain == 'esthetics'" class="mobile">{{ $t("common.type") }}</th>
						<td v-if="$store.state.domain == 'esthetics'" class="type" :rowspan="service.rowspan">
							{{ service.step ? servicesSteps[service.step] : '' }}
							<br/>
							<i>{{ service.type ? $t( 'data.servicestype[' + service.type + ']' ) : '' }}</i>
						</td>
						<td class="td-button mobile-100" :rowspan="service.rowspan">
							<button
								class="btn-mod btn btn-outline-success"
								type="button"
								@click="editService( service )"
							>
								<i class="fas fa-edit"></i>
								<span style="font-size: 0.85em; padding-left: 0.25rem">Modifier</span>
							</button>
						</td>
					</tr>
					<tr v-if="!service.oneline" :key="service.id + '-2'" class="desktop">
						<td class="hair">
							{{ $t('data.hairlength.half') }}
						</td>
						<td v-if="service.price_female == null" class="td-small cell-f" rowspan="1">
							{{ service.female_half_price }}
						</td>
						<td v-if="service.price_male == null" class="td-small cell-h" rowspan="1" />
						<td v-if="service.duration_female == null" class="td-small cell-f" rowspan="1">
							{{ service.female_half_time }}
						</td>
						<td v-if="service.duration_male == null" class="td-small cell-h" rowspan="1"
						/>
					</tr>
					<tr v-if="!service.oneline" :key="service.id + '-3'" class="desktop">
						<td class="hair">
							{{ $t('data.hairlength.long') }}
						</td>
						<td v-if="service.price_female == null" class="td-small cell-f" rowspan="1">
							{{ service.female_long_price }}
						</td>
						<td v-if="service.price_male == null" class="td-small cell-h" rowspan="1">
							{{ service.male_long_price }}
						</td>
						<td v-if="service.duration_female == null" class="td-small cell-f" rowspan="1">
							{{ service.female_long_time }}
						</td>
						<td v-if="service.duration_male == null" class="td-small cell-h" rowspan="1">
							{{ service.male_long_time }}
						</td>
					</tr>
				</template>
				<tr id="btn-add">
					<td class="td-button add-presta mobile-100" colspan="12">
						<button
							id="add-presta"
							class="btn-add fas fa-plus btn btn-primary"
							type="button"
							@click="editService( null );"
						/>
					</td>
				</tr>
			</tbody>
		</table>
		<EditService
			v-if="showEditModal"
			:technic="false"
			:title="$t('common.service')"
			:prefillData="currentlyEditing"
			@close="showEditModal = false"
		/>
	</div>
</template>

<script>
	import EditService from "../../modals/EditService.vue";
	import ServiceMatrixMobile from "./ServiceMatrixMobile.vue";
	import { recurseDependencies } from '../../servicesLogic.js'

	export default {
		components: {
			EditService,
			ServiceMatrixMobile
		},
		data() {
			return {
				showEditModal: false,
				currentlyEditing: null,
				activeServices: [],
			};
		},
		computed: {
			servicesSteps() {
				return this.$store.getters.salonTypes().map( type => this.$t('data.servicesstep')[type] ).reduce( (a, b) => ({ ...a, ...b }), {} )
			},
			services() {
				var services = this.$store.getters.getServices();
				services = services.sort( ( a, b ) => a.id - b.id );
				if ( this.$store.state.domain == "esthetics" ) {
					for ( var i = 0; i < services.length; i++ ) {
						var service = services[i];
						if ( service.type < 0 ) {
							// type == "Technic"
							services.splice( i--, 1 );
							continue;
						}
						var same_price_female = ( service.female_short[0] == service.female_half[0] && service.female_short[0] == service.female_long[0] );
						var same_price_male = ( service.male_short[0] == service.male_long[0] ); // no half for men
						var same_duration_female = ( service.female_short[1] == service.female_half[1] && service.female_short[1] == service.female_long[1] );
						var same_duration_male = ( service.male_short[1] == service.male_long[1] ); // no half for men
						if ( service.female_short[0] ) {
							service.female_short_price = service.female_short[0] + "€";
						}
						if ( service.male_short[0] ) {
							service.male_short_price = service.male_short[0] + "€";
						}
						if ( service.female_half[0] ) {
							service.female_half_price = service.female_half[0] + "€";
						}
						if ( service.male_half[0] ) {
							service.male_half_price = service.male_half[0] + "€";
						}
						if ( service.female_long[0] ) {
							service.female_long_price = service.female_long[0] + "€";
						}
						if ( service.male_long[0] ) {
							service.male_long_price = service.male_long[0] + "€";
						}
						if ( service.child[0] ) {
							service.child_price = service.child[0] + "€";
						}
						if ( service.female_short[1] ) {
							service.female_short_time = service.female_short[1] + " min";
						}
						if ( service.male_short[1] ) {
							service.male_short_time = service.male_short[1] + " min";
						}
						if ( service.female_half[1] ) {
							service.female_half_time = service.female_half[1] + " min";
						}
						if ( service.male_half[1] ) {
							service.male_half_time = service.male_half[1] + " min";
						}
						if ( service.female_long[1] ) {
							service.female_long_time = service.female_long[1] + " min";
						}
						if ( service.male_long[1] ) {
							service.male_long_time = service.male_long[1] + " min";
						}
						if ( service.child[1] ) {
							service.child_time = service.child[1] + " min";
						}
						if ( same_price_female && service.female_short[0] /*!== undefined && service.female_short[0] !== null*/ ) {
							service.price_female = service.female_short[0] + "€";
						}
						if ( same_price_male && service.male_short[0] /*!== undefined && service.male_short[0] !== null*/ ) {
							service.price_male = service.male_short[0] + "€";
						}
						if ( same_duration_female && service.female_short[1] /*!== undefined && service.female_short[1] !== null*/ ) {
							service.duration_female = service.female_short[1] + " min";
						}
						if ( same_duration_male && service.male_short[1] /*!== undefined && service.male_short[1] !== null*/ ) {
							service.duration_male = service.male_short[1] + " min";
						}
						service.oneline = ( same_price_female && same_price_male && same_duration_female && same_duration_male );
						service.onelinePrice = ( same_price_female && same_price_male );
						service.onelineDuration = ( same_duration_female && same_duration_male );
						service.rowspan = service.oneline ? 1 : 3;
						service.rowspanPrice = service.onelinePrice ? 1 : 3;
						service.rowspanDuration = service.onelineDuration ? 1 : 3;
						if ( service.dependencies ) {
							service.computedDependencies = recurseDependencies( this.$store.state.services, service )
							/*
							for ( var j = 0; j < service.dependencies.length; j++ ) {
								service.dependencies[j] = this.$store.getters.getService( service.dependencies[j] );
							}
							*/
						}
						service.typeText = this.$t( "data.servicestype[" + service.type + "]" )
					}
				} else if ( this.$store.state.domain == "coachs" ) {
					for ( let i = 0; i < services.length; i++ ) {
						const service = services[i]
						service.oneline = true
						service.price_female = service.female[0] + "€"
						service.price_male = service.male[0] + "€"
						service.duration_female = service.female[1] + " min"
						service.duration_male = service.male[1] + " min"
					}
				}
				return services;
			}
		},
		methods: {
			editService( service ) {
				this.showEditModal = true
				this.currentlyEditing = service
			},
			toggleServiceActive({ id }) {
				const index = this.activeServices.indexOf(id)
				if ( index >= 0 ) {
					this.activeServices.splice(index, 1)
				} else {
					this.activeServices.push(id)
				}
			}
		}
	};
</script>
