<template>
	<table>
		<tbody>
			<tr>
				<td>{{ $t("common.price") }}</td>
				<td v-if="service.price_female != null" class="td-small cell-f" :rowspan="service.rowspanPrice">
					{{ service.price_female }}
				</td>
			</tr>
			<tr>
				<td>{{ $t("common.duration") }}</td>
				<td v-if="service.duration_female != null" class="td-small cell-f" :rowspan="service.rowspanDuration">
					{{ service.duration_female }}
				</td>
			</tr>
		</tbody>
	</table>
<!--
	<table>
		<thead>
			<tr>
				<th rowspan="2" v-if="$store.getters.salonPrestationsHasLength()">
					{{ $t("common.hair") }}
				</th>
				<th
					colspan="1"
					:style="{ width: !$store.getters.salonPrestationsHasLength() && !$store.getters.salonAcceptsChildren() ? '50%' : null }"
				>
					{{ $t("common.wom") }}
				</th>
				<th colspan="1">
					{{ $t("common.men") }}
				</th>
				<th colspan="1" rowspan="1" v-if="$store.getters.salonAcceptsChildren()">
					{{ $t("common.youth") }}
				</th>
			</tr>
			<tr>
				<th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2">
					{{ $t("common.price") }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr :key="service.id + '-1'" class="row-border-top">
				<td class="hairs" :rowspan="1" v-if="$store.getters.salonPrestationsHasLength()">
					{{ service.onelinePrice ? $t('common.all') : $t('data.hairlength.short') }}
				</td>
				<td v-if="service.price_female != null" class="td-small cell-f" :rowspan="service.rowspanPrice">
					{{ service.price_female }}
				</td>
				<td v-else class="td-small cell-f" :rowspan="1">
					<template v-if="service.female_short_price || $store.getters.salonPrestationsHasLength()">{{ service.female_short_price }}</template>
					<span v-else class="price-placeholder">{{ $t('common.priceplaceholder') }}</span>
				</td>
				<td v-if="service.price_male != null" class="td-small cell-h" :rowspan="service.rowspanPrice">
					{{ service.price_male }}
				</td>
				<td v-else class="td-small cell-h" :rowspan="1">
					<template v-if="service.male_short_price || $store.getters.salonPrestationsHasLength()">{{ service.male_short_price }}</template>
					<span v-else class="price-placeholder">{{ $t('common.priceplaceholder') }}</span>
				</td>
				<td class="td-small cell-enf" :rowspan="service.rowspanPrice" v-if="$store.getters.salonAcceptsChildren()">
					<template v-if="service.child_price || $store.getters.salonPrestationsHasLength()">{{ service.child_price }}</template>
					<span v-else class="price-placeholder">{{ $t('common.priceplaceholder') }}</span>
				</td>
			</tr>
			<tr v-if="!service.onelinePrice && $store.getters.salonPrestationsHasLength()" :key="service.id + '-2'">
				<td class="hairs">
					{{ $t('data.hairlength.half') }}
				</td>
				<td v-if="service.price_female == null" class="td-small cell-f" rowspan="1">
					{{ service.female_half_price }}
				</td>
				<td v-if="service.price_male == null" class="td-small cell-h" rowspan="1" />
			</tr>
			<tr v-if="!service.onelinePrice && $store.getters.salonPrestationsHasLength()" :key="service.id + '-3'">
				<td class="hairs">
					{{ $t('data.hairlength.long') }}
				</td>
				<td v-if="service.price_female == null" class="td-small cell-f" rowspan="1">
					{{ service.female_long_price }}
				</td>
				<td v-if="service.price_male == null" class="td-small cell-h" rowspan="1">
					{{ service.male_long_price }}
				</td>
			</tr>
		</tbody>
		<thead>
			<tr>
				<th rowspan="1" class="no-border-right" v-if="$store.getters.salonPrestationsHasLength()"></th>
				<th :colspan="$store.getters.salonAcceptsChildren() ? 3 : 2" class="no-border-left">
					{{ $t("common.durate") }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr :key="service.id + '-1'" class="row-border-top">
				<td class="hairs" :rowspan="1" v-if="$store.getters.salonPrestationsHasLength()">
					{{ service.onelineDuration ? $t('common.all') : $t('data.hairlength.short') }}
				</td>
				<td v-if="service.duration_female != null" class="td-small cell-f" :rowspan="service.rowspanDuration">
					{{ service.duration_female }}
				</td>
				<td v-else class="td-small cell-f" :rowspan="1">
					{{ service.female_short_time }}
				</td>
				<td v-if="service.duration_male != null" class="td-small cell-h" :rowspan="service.rowspanDuration">
					{{ service.duration_male }}
				</td>
				<td v-else class="td-small cell-h" :rowspan="1">
					{{ service.male_short_time }}
				</td>
				<td class="td-small cell-enf" :rowspan="service.rowspanDuration" v-if="$store.getters.salonAcceptsChildren()">
					{{ service.child_time }}
				</td>
			</tr>
			<tr v-if="!service.onelineDuration && $store.getters.salonPrestationsHasLength()" :key="service.id + '-2'">
				<td class="hairs">
					{{ $t('data.hairlength.half') }}
				</td>
				<td v-if="service.duration_female == null" class="td-small cell-f" rowspan="1">
					{{ service.female_half_time }}
				</td>
				<td v-if="service.duration_male == null" class="td-small cell-h" rowspan="1"
				/>
			</tr>
			<tr v-if="!service.onelineDuration && $store.getters.salonPrestationsHasLength()" :key="service.id + '-3'">
				<td class="hairs">
					{{ $t('data.hairlength.long') }}
				</td>
				<td v-if="service.duration_female == null" class="td-small cell-f" rowspan="1">
					{{ service.female_long_time }}
				</td>
				<td v-if="service.duration_male == null" class="td-small cell-h" rowspan="1">
					{{ service.male_long_time }}
				</td>
			</tr>
		</tbody>
	</table>
-->
</template>

<script>

export default {
	props: [
		"service"
	],
	data() {
		return {}
	},
	computed: {
	},
	methods: {
	}
};
</script>
